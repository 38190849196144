define("discourse/plugins/swapd-interested/discourse/templates/connectors/category-custom-settings/interested-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.swapd_interested_enabled}}
    <h3>{{i18n "swapd_interested.title"}}</h3>
    <section class="field">
      <div class="allow-interested">
        <label class="checkbox-label">
          {{input
            type="checkbox"
            checked=this.category.custom_fields.allow_interested
          }}
          {{i18n "swapd_interested.allow_interested"}}
        </label>
      </div>
    </section>
  {{/if}}
  */
  {
    "id": "K3I94Vz+",
    "block": "[[[41,[30,0,[\"siteSettings\",\"swapd_interested_enabled\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"swapd_interested.title\"],null]],[13],[1,\"\\n  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"allow-interested\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[1,[28,[35,6],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"allow_interested\"]]]]]],[1,\"\\n        \"],[1,[28,[35,2],[\"swapd_interested.allow_interested\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"h3\",\"i18n\",\"section\",\"div\",\"label\",\"input\"]]",
    "moduleName": "discourse/plugins/swapd-interested/discourse/templates/connectors/category-custom-settings/interested-settings.hbs",
    "isStrictMode": false
  });
});